import { AppBar, Box, Button, Container, Tab, Tabs, Toolbar, Menu, MenuItem } from "@mui/material";
import { useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NeotaskLogo from "../assets/neotask-logo.png";
import { CompanyContext } from "../contexts/CompanyContext";
import UserIcon from "@mui/icons-material/Person";
import { AuthContext } from "../contexts/AuthContext";
import { Login, KeyboardArrowDown, AdminPanelSettings, Logout } from "@mui/icons-material";

export function AppBarPublic() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAdmin = () => {
    navigate('/tasks');
    handleClose();
  };

  const { cover, logo } = useContext(CompanyContext);
  const { isAuth, getUserData, logout } = useContext(AuthContext);
  const location = useLocation();
  const user = getUserData();

  return (
    <>
      <Box sx={{
        height: 180,
        backgroundImage: "url('" + cover + "')",
        backgroundRepeat: "no-repeat",
        backgroundColor: (t) =>
          t.palette.mode === "light"
            ? t.palette.grey[50]
            : t.palette.grey[900],
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}>
        <Box sx={{
          width: '100%',
          height: '100%',
          backgroundColor: '#0b2643',
          opacity: '0.9',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <Box sx={{
            backgroundColor: 'white',
            width: 160,
            height: 160,
            padding: 2,
            borderRadius: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <img
              src={logo ?? NeotaskLogo}
              style={{
                width: 150,
                height: 150,
                objectFit: 'contain'
              }}
              alt="Logo"
            />
          </Box>
        </Box>
      </Box>
      <AppBar position="static" sx={{ backgroundColor: 'white', boxShadow: 'none', borderBottom: '1px solid #e0e0e0', marginBottom: 0 }}>
        <Container>
          <Toolbar sx={{ justifyContent: 'space-between' }}>
            <Tabs
              value={location.pathname === '/external-area/flows' ? 0 : (location.pathname === '/external-area/processes' ? 1 : null)}
              TabIndicatorProps={{
                sx: {
                  height: 3,
                }
              }}
              sx={{
                '& .MuiTab-root': {
                  textTransform: 'none',
                  fontSize: '1rem',
                  minWidth: 100,
                  padding: '12px 24px',
                  color: 'text.primary',
                  '&.Mui-selected': {
                    color: 'primary.main'
                  }
                }
              }}
            >
              <Tab
                label="FLUXOS"
                component={Link}
                to="/external-area/flows"
              />
              <Tab
                label="PROCESSOS"
                component={Link}
                to="/external-area/processes"
              />
            </Tabs>
            
              <Button
                component={isAuth ? 'button' : Link}
                to={isAuth ? undefined : "/login"}
                onClick={isAuth ? handleClick : undefined}
                endIcon={isAuth ? <KeyboardArrowDown /> : null}
                startIcon={<UserIcon />}
                sx={{
                  color: 'text.primary',
                  textTransform: 'none',
                  fontSize: '1rem'
                }}
              >
                {isAuth ? user?.name : "Login"}
              </Button>
              
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                { user?.is_external === false && (
                  <MenuItem onClick={handleAdmin}>
                    <AdminPanelSettings sx={{ mr: 1 }} />
                    Voltar para a área restrita
                  </MenuItem>
                )}
                <MenuItem onClick={() => {
                  handleClose();
                  logout();
                }}>
                  <Logout sx={{ mr: 1 }} />
                  Logout
                </MenuItem>
              </Menu>
           
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
} 