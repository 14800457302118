import axios from 'axios';
import { enqueueSnackbar } from 'notistack';

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API + '/api',
  headers: {
    "Content-type": "application/json"
  },
});

axiosClient.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token;

  return config;
});

axiosClient.interceptors.response.use((response) => response, (error) => {

  if (error?.response?.data?.message === 'Não foi possível encontrar o usuário do token' || error?.response?.data?.message === 'Informe um token de autenticação') {
    localStorage.removeItem('token');
    //window.location.reload();
  }

  enqueueSnackbar(error?.response?.data?.message || 'Erro desconhecido', { variant: 'error', });

  return Promise.reject(error);
});

export default axiosClient;