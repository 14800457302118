import { lazy, useEffect } from 'react';
import './App.css';
import { ThemeProvider, createTheme } from '@mui/material';
import { QueryClient, QueryClientProvider } from 'react-query';
import { yellow } from '@mui/material/colors';
import { RouterProvider, createBrowserRouter, Navigate, useLocation, PathRouteProps, useParams } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { CompanyProvider } from './contexts/CompanyContext';
import { LayoutBase } from './components/LayoutBase';
const twentyFourHoursInMs = 1000 * 60 * 60 * 24;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: twentyFourHoursInMs,
    },
  },
});

const defaultTheme = createTheme({
  palette: {
    background: {
      default: '#F5F5F5'
    },
    primary: {
      main: '#0b2643',
    },
    secondary: yellow
  }
});

const Flows = lazy(() => import("./screens/Flows"));
const Processes = lazy(() => import("./screens/Processes"));
const ProcessDetail = lazy(() => import("./screens/Processes/Detail"));
const Settings = lazy(() => import("./screens/Settings"));
const Tasks = lazy(() => import("./screens/Tasks"));
const FlowDetail = lazy(() => import("./screens/Settings/Flows/Detail"));
const DatasourceDetail = lazy(() => import("./screens/Settings/Datasources/Detail"));
const Login = lazy(() => import("./screens/Login"));

const FlowsExternal = lazy(() => import("./screens/ExternalArea/Flows"));
const ProcessesExternal = lazy(() => import("./screens/ExternalArea/Processes"));
const FlowDetailExternal = lazy(() => import("./screens/ExternalArea/Flows/Detail"));
const ProcessDetailExternal = lazy(() => import("./screens/ExternalArea/Processes/Detail"));

const RedirectToFlow = () => {
  const { identifier } = useParams();
  return <Navigate to={`/external-area/flow/${identifier}`} replace />;
}

function App() {

  const subdomain = window.location.hostname.split(".")[0];

  useEffect(() => {
    if (subdomain === 'www') {
      window.location.href = 'https://neotask.com.br';
    }
  }, [subdomain]);

  if(subdomain === "www" || !subdomain) return <></>;

  const routes = {
    element: <LayoutBase />,
    children: [
      { path: "/login", element: <Login />},
      { path: "/tasks", element: <Tasks />},
      { path: "/settings/:tab?", element: <Settings /> },
      { path: "/flows", element: <Flows /> },
      { path: "/processes", element: <Processes /> },
      { path: "/process/:code", element: <ProcessDetail /> },
      { path: "/flow/:id", element: <FlowDetail /> },
      { path: "/datasource/:uuid", element: <DatasourceDetail /> },
      { path: "/external-area/flows", element: <FlowsExternal />},
      { path: "/external-area/flow/:identifier", element: <FlowDetailExternal />},
      { path: "/external-area/processes", element: <ProcessesExternal />},
      { path: "/external-area/process/:code", element: <ProcessDetailExternal />},
      { path: "/public/create-process/:identifier", element: <RedirectToFlow />},
      { path: "/external-area", element: <Navigate to="/external-area/flows" replace />},
      { path: "*", element: <Navigate to="/tasks" replace /> },
    ]
  }

  return (
    <SnackbarProvider maxSnack={6} autoHideDuration={8000}>
      <QueryClientProvider client={queryClient}>
        <CompanyProvider>
            <ThemeProvider theme={defaultTheme}>
              <RouterProvider router={createBrowserRouter([routes])}/>
            </ThemeProvider>
        </CompanyProvider>
      </QueryClientProvider>
    </SnackbarProvider>
  );
}

export default App;